import React, { useState } from 'react';
import { useAsync, UseAsyncReturn } from 'react-async-hook';

import {
  downloadShared,
  getShared,
  SharedPhotoResult,
  SharedPhotoResultExpired,
} from 'appAPI';
import { usePageParams } from 'layout/appContext';
import { AppSEOTitle } from '../../appSEO';
import PageError, { PageErrorContent } from '../pageError';
import { MainLayout } from '../../layout/mainLayout';
import { ShSpinner, ShButtonAsync, ShTitle } from '@shoootin/components-web';
import { ShColors } from '@shoootin/design-tokens';
import { ShMoment } from '@shoootin/utils';
import { FrontKeys, ShText } from '@shoootin/translations';

export const SharedLoading = () => (
  <MainLayout className="light" showContact={false}>
    <AppSEOTitle title="Loading" />
    <div css={{ padding: 150 }}>
      <ShSpinner size={'m'} />
    </div>
  </MainLayout>
);

export const SharedExpired = () => (
  <MainLayout className="light" showContact={false}>
    <AppSEOTitle title="Expired link" />
    <PageErrorContent message={'This link is expired.'} />
  </MainLayout>
);

const SharedPageLoaded = ({
  result,
  download,
}: {
  result: SharedPhotoResult | SharedPhotoResultExpired;
  download: any;
}) => {
  if (result.expired) {
    return <SharedExpired />;
  }

  const {
    photos,
    shared: { sharedBy, expiration, title },
  } = result;
  const [displayedIndex, setDisplayedIndex] = useState<number>(0);

  setTimeout(() => {
    if (displayedIndex === photos.length - 1) {
      setDisplayedIndex(0);
    } else {
      setDisplayedIndex(displayedIndex + 1);
    }
  }, 7500);

  return (
    <MainLayout className="light" showContact={false}>
      <AppSEOTitle title={title} />
      <div
        css={{
          paddingTop: 112,
        }}
      >
        <div
          css={{
            height: 'calc(100vh - 112px)',
            position: 'relative',
          }}
        >
          {photos.map((photo, index) => {
            return (
              <div
                key={photo}
                css={{
                  position: 'absolute',
                  backgroundImage: `url(${photos[index]})`,
                  backgroundColor: ShColors.black,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  transition: 'opacity 0.5s ease-in-out',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                style={{
                  opacity: index === displayedIndex ? 1 : 0,
                }}
              />
            );
          })}
        </div>
        <div
          css={{
            position: 'absolute',
            bottom: 50,
            left: 50,
            backgroundColor: ShColors.white,
            padding: 50,
            maxWidth: 400,
          }}
        >
          <div css={{ paddingBottom: 10 }}>
            <ShTitle size={4}>
              <ShText<FrontKeys> message={'common_actions_download'} />
            </ShTitle>
          </div>
          <div css={{ paddingBottom: 40 }}>
            <ShTitle size={5}>{title}</ShTitle>
          </div>

          <div css={{ paddingBottom: 40 }}>
            <ShText
              message={'client_sharing_frontDescription'}
              values={{
                sharedBy: <b>{sharedBy}</b>,
                date: <b>{ShMoment(expiration).format('LL')}</b>,
              }}
            />
          </div>

          <div css={{ textAlign: 'right' }}>
            <ShButtonAsync onClick={download}>
              <ShText<FrontKeys> message={'common_actions_download'} />
            </ShButtonAsync>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const SharedPage = () => {
  const { sharedId } = usePageParams();

  if (!sharedId) {
    return <PageError />;
  }

  const asyncShared: UseAsyncReturn<
    SharedPhotoResult | SharedPhotoResultExpired
  > = useAsync(async () => {
    return getShared({ id: sharedId });
  }, [sharedId]);

  const initiateFileDownload = (downloadLink: string) => {
    const link = document.createElement('a');
    link.href = downloadLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const download = async () => {
    const result = await downloadShared({ id: sharedId });

    console.log(result);
    initiateFileDownload(result.downloadLink);
  };

  return (
    <div>
      {asyncShared.loading && <SharedLoading />}
      {asyncShared.error && (
        <>
          <MainLayout className="light" showContact={false} pageName="error">
            <PageErrorContent message={asyncShared.error.message} />
          </MainLayout>
        </>
      )}
      {asyncShared.result && (
        <SharedPageLoaded result={asyncShared.result} download={download} />
      )}
    </div>
  );
};

export default SharedPage;
